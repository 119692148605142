button {
    background: none;
}

.loss-full-screen {
    height: calc(var(--vh, 1vh) * 100);
}

.loss-splash {
    display: flex;
}